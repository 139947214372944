import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box, IconButton, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ProductCardProps } from '../FilteritemsController';
import {prdImg} from '../assets'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Define the type for the product data



const ProductCard: React.FC<ProductCardProps> = ({ attributes,type,id , user ,handelDelete,navigtion}) => {
  const { name,type : typeofprd, stock, price, classification, min_quantity, available_quantity ,images} = attributes;

  return (
    <Card
      sx={{
        maxWidth: 268,
        borderRadius: '8px',
        overflow: 'hidden',
        fontFamily: 'Inter, sans-serif',
        maxHeight:'361px',
        width:'268px',
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
      }}
    >
      <Box
        onClick={navigtion}
        component="img"
        src={images?.[0]?.url || prdImg.default}
        alt={name}
        sx={{ height: 150, width: '100%', objectFit: 'cover', padding:'10px', borderRadius:5, maxWidth:'90%' , alignSelf:'center' }}
      />
      <CardContent sx={{p:0, px:'10px' ,gap:'10px',paddingBottom:0, '&:last-child': {
      paddingBottom: '18px',
    }, }}>
      { user.attributes.type !== "BuyerAccount" ?  <>
         <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 'bold', color: '#334155', fontFamily: 'inter', display: 'flex', overflow: 'hidden' }}>
            {name.toString().length > 8 ? name.toString().slice(0, 6) + "..." : name.toString()} &nbsp; <Typography variant="subtitle2" sx={{ fontSize: 14, fontWeight: 'bold', color: '#059669', fontFamily: 'inter' }}>
              ( {type} stock {stock} )
            </Typography>
          </Typography>
        </>: <Box display="flex" flexDirection="column" alignItems="flex-start">
      {/* First Row */}
      <Box display="flex" alignItems="center" mb={1}>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '18px',
            marginRight: '8px',
          }}
        >
          Breeder: 
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
         Auto Seeds
        </Typography>
        <Box display="flex" alignItems="center" ml={1}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '18px',
              marginLeft: '4px',
            }}
          >
          </Typography>
        </Box>
      </Box>

      {/* Second Row */}
      <Typography
        variant="body1"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
        }}
      >
        {name}<span style={{ color: '#059669' }}>&nbsp;({typeofprd} stock)</span>
      </Typography>

      {/* Quantity Controls */}
      <Box display="flex" alignItems="center" mt={1}>
        <IconButton size="small">
          <RemoveIcon sx={{ color: '#0F172A' ,fontSize:14}} />
        </IconButton>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#475569',
            padding: '0 8px',
            background:'#F5F5F4',
            borderRadius:8
          }}
        >
          {available_quantity} Qty
        </Typography>
        <IconButton size="small">
          <AddIcon sx={{ color: '#0F172A' ,fontSize:14 }} />
        </IconButton>
      </Box>
    </Box>}
        
        <Typography 
          sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems:'center',
              fontSize: 16,
              mt: '10px',
              fontFamily: 'Inter',
              fontWeight: 700,
              color: '#334155',
          }}
      >
          <span>${price} USD</span> 
              {user.attributes.type === "BuyerAccount" ?<Button
                  size="small"
                  sx={{
                      color: '#007A2F',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textDecoration: 'none', 
                      backgroundColor: '#007A2F33',
                      fontWeight: 'bold', 
                      textTransform: 'capitalize', 
                      borderRadius:'8px',
                      width:105,
                      height:32
                  }}
              >
                  <span style={{
                      marginTop: '4px',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      color: '#007A2F',
                      textDecoration: 'none',
                      fontWeight: 'bold'
                  }}>
                      Add to cart
            </span>
              </Button>:<></>}
        </Typography>
       
        {user.attributes.type !== "BuyerAccount" &&
         <div style={{marginTop:'10px'}}> <Typography sx={{ fontSize: 14,mt:'10px',fontFamily:'Inter', color: '#334155' , background: '#F5F5F4' ,p:'4px', borderRadius:'8px',display: 'inline list-item', flexShrink:'1'}}>
          {available_quantity} Qty
        </Typography>
        </div>}
        { user.attributes.type !== "BuyerAccount" && <><Typography sx={{ fontSize: 14,mt:'10px', fontFamily:'Inter', fontWeight:400, color: '#334155' }}>
          Minimum order qty: {min_quantity}
        </Typography>
        <Typography sx={{ fontSize: 14,mt:'10px',fontFamily:'Inter', color: '#334155' ,  }}>
          Classification: &nbsp;<span style={{ fontSize: 14,fontFamily:'Inter', color: '#4F46E5' ,  }} >{classification}</span>
        </Typography></>}
      </CardContent>
      { user.attributes.type !== "BuyerAccount" &&<CardActions sx={{pt:'0',mt:0}}>
        <Button
            size="small"
            sx={{
              color: '#334155',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration:'capitalize',

            }}
            onClick={handelDelete}
          >
            <DeleteOutlineRoundedIcon fontSize="small" sx={{ marginRight: 0.5 }} />
            <span style={{marginTop:'4px',fontSize: 14,fontFamily:'Inter',textTransform:'capitalize', color: '#334155' , textDecoration:'capitalize'}}>Delete</span>
        </Button>
        <span style={{padding:'0 4px', color:'#E2E8F0'}}>|</span>
        <Button
            size="small"
            sx={{
              color: '#334155',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
          <EditIcon fontSize="small"  sx={{ marginRight: 0.5 }} /> <span style={{marginTop:'4px',fontSize: 14,fontFamily:'Inter',textTransform:'capitalize', color: '#334155' , textDecoration:'capitalize'}}>Edit</span>
        </Button>
      </CardActions>}
    </Card>
  );
};

export default ProductCard;
