import React from "react";
// Customizable Area Start
import {
  StyleSheet,
 
  SafeAreaView,
 
} from "react-native";
import {Grid, Box,
  Button,
  Typography,
  Select,
  MenuItem,} from "@mui/material"
import { AppHeader } from "../../../components/src/AppHeader.web";
import {prdImg} from './assets'
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";
import { bold } from "@uiw/react-md-editor";
import { isArray } from "lodash";

export default class ProductDiscription extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(){
    let id = new URLSearchParams(window.location.search).get('navigationBarTitleText');
    this.getProductDetails(+`${id}`)
      
  }

  isBuyerAccount= this.state.isBuyer
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
        {/* Customizable Area Start */}
        <AppHeader navigtion={this.props.navigation} />
        <Box sx={{ maxWidth: '75%', margin: '0 auto', padding: '20px' }}>
        {/* Back to Products Link */}
        <Typography
          variant="body1"
          data-test-id='backbtn'
        
          sx={{ fontSize: '16px',color:'#475569', fontFamily:'inter',fontWeight:400, marginBottom: '20px', cursor: 'pointer' }}
        >
          <span style={{fontFamily:'inter', fontWeight:'bold'}}>❮</span> Back to products
        </Typography>
        <Box sx={{ display: 'flex', gap: '40px' , justifyContent:'space-between' , alignItems:'center' }}>
         
         
          <Box sx={{ width: '50%' }}>
          <>
          <div style={{display:'flex',gap:'16px', justifyContent:'center',alignItems:'center'}}>
          <span>❮</span>
            <img
              src={this.state.selectedProduct?.attributes?.images?.length ? this.state.selectedProduct.attributes.images[0].url:prdImg.default}
              alt="Product Main"
              style={{ width: '100%', minWidth:'292px',objectFit:'cover',height:'382px',maxWidth:'292px', borderRadius: '8px', marginBottom: '10px' }}
            />
            <span >{" "}❯</span>
            </div>

            <Grid container spacing={2} sx={{px:'32px'}}>
            {this.state.selectedProduct?.attributes.images?.length && this.state.selectedProduct.attributes.images.map(image=> <Grid item xs={4} sx={{px:0}}>
                <img
                  src={image.url}
                  alt="Thumbnail 1"
                  style={{width:'80px',height:'80px', borderRadius: '8px', marginRight:'8px' }}
                />
              </Grid>)}
            
            </Grid>
            </>
          </Box>
          
          <Box sx={{display:'flex', alignItems:'flex-start', alignSelf:'start'}}>
          <Box sx={{   minWidth:'436px', alignItems:'start'}}>
            <Typography variant="h5" sx={{ fontSize: '18px', fontFamily:'inter', fontWeight: '700' }}>
              {this.state.selectedProduct?.attributes.name}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '14px',color:'#0F172A', fontFamily:'inter', marginBottom: '16px' }}>
              ({this.state.selectedProduct?.attributes.type})
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', fontFamily:'inter',color: '#64748B', marginBottom: '20px' }}>
            {this.state.selectedProduct?.attributes.product_feature}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '24px', fontFamily:'inter',color: '#334155', fontWeight: '700', marginBottom: '20px' }}>
              ${this.state.selectedProduct?.attributes.price} USD 
            </Typography>
            <Box sx={{ display: 'flex', paddingTop:'20px',flexDirection:'column',borderTop:'1px solid #E2E8F0', fontFamily:'inter',alignItems: 'flex-start', marginBottom: '20px' }}>
            
              {this.state.selectedProduct?.attributes.specifications_attributes?.map(item=>{
                return <Typography variant="body1" sx={{ fontSize: '16px' , fontFamily:'inter',color: '#44403C'            }}>
                  {item.key} : {item.value} 
                  </Typography>
              })}
              
            
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center',borderTop:'1px solid #E2E8F0', marginBottom: '20px' }}>
            </Box>
            <Box sx={{ display: 'flex',justifyContent:'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#475569', fontFamily:'inter', marginRight: '10px' }}>
                Qty:  {this.state.selectedProduct?.attributes.available_quantity}
              </Typography>
              
              <Typography variant="body1" sx={{ fontSize: '14px',color:'#475569', fontFamily:'inter', marginLeft: '20px' }}>
                Available Qty:  {this.state.selectedProduct?.attributes.available_quantity}
              </Typography>
            </Box>
           {this.isBuyerAccount &&<> <Select defaultValue={1} size="small" sx={{ width: '70px' }}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
              <Button
              variant="contained"
              color="success"

              sx={{ fontSize: '16px',display:'block',textTransform: 'capitalize',width:'197px', marginTop:'36px', padding: '10px', fontWeight: '700' }}
            >
              Add to cart
            </Button> </>}
          </Box>
          </Box>
        </Box>
       
        {/* Dealer Description */}
      <Box sx={{ marginTop: '40px' ,width:'624px'}}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Dealer Description
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '16px',fontWeight:400,fontFamily:'inter', marginBottom: '20px' ,color: '#64748B'}}>
        {this.state.selectedProduct?.attributes.description} &nbsp;
        <Typography
          variant="body2"
          component='span'
          sx={{ fontSize: '14px',fontWeight:400,fontFamily:'inter',color: '#007BFF', cursor: 'pointer' }}
        >
          View profile
        </Typography>
        </Typography>
       
      </Box>

      {/* Product Detail */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h6" sx={{ fontSize: '20px',color: '#1E293B',fontFamily:'inter', fontWeight: '700', marginBottom: '20px' }}>
          Product Detail
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' ,fontFamily:'inter',}}>
        {this.state.selectedProduct?.attributes.specifications_attributes?.map(item=>{
              return <Typography variant="body1" sx={{ fontSize: '14px' ,fontFamily:'inter',}}>
               <span style={{display:'felx' ,justifyContent:'space-between'}}>
               <b> {item.key}</b>  <span style={{marginLeft:'48px',color:'#475569'}}>{item.value}</span>
                </span>
                </Typography>
            })}
          
        </Box>
      </Box>
    </Box>
      
        {/* Customizable Area End */}
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100vw",
    backgroundColor: "#ffffffff"
  },

});
// Customizable Area End
