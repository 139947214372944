import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";

export interface Specification {
  id?: number;
  key: string;
  value: string;
  _destroy?: boolean;
}

export interface ProductImage {
  id?: number | null;
  url?: string;
  file?: File;
}

export interface Product {
  id: number;
  type: string;
  attributes: {
    name: string;
    description: string | null;
    price: number | string;
    type: string;
    classification: string;
    available_quantity: number;
    images: ProductImage[];
    specifications_attributes: Specification[];
    product_feature?:string;
  };
}

export type ProductAttributes = {
  id: number;
  name: string;
  product_feature: string | null;
  stock: number;
  price: number;
  type: string;
  classification: string;
  account_id: number;
  min_quantity: number;
  available_quantity: number;
  images: {
    id: string|null,
    url:string|null
  }[],
  specifications_attributes: any[];
};
export type ProductCardProps = {
  
    id: string;
    type: string;
    attributes: ProductAttributes;
    user:any
    navigtion?:any;
    handelDelete:()=>void
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string|null;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  Products : ProductCardProps[];
  openDrw:boolean;
  selectedProduct: Product | null;
  isBuyer?: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      Products:[],
      selectedProduct: null,
      openDrw:false,
      isBuyer:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if(!localStorage.getItem('user')){
      this.getLoggendInUserData()
      }
    this.setState({token:localStorage.getItem('authToken')})
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

 

  async receive(from: string, message: Message) {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallID  === this.useDetailsApiCallID){
     
      this.saveLoggedInUserData(responseJson.data)
      this.setState({isBuyer:responseJson.data.attributes?.type === "BuyerAccount"})
   
    }
    if (this.getProductDetailsApiCallId === apiRequestCallID) {
     
      this.handleGetProductDetailsResponse(responseJson);
    }
    if (apiRequestCallID===this.DeleteProductDetailsApiCallId){
     
        this.getListRequest(localStorage.getItem('authToken')??"");
      
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token??"");
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
      
        this.setState({ arrayHolder: responseJson.data });
       
        this.setState({ Products: responseJson.data });
        let params = this.props.navigation.state.params;
        if (params?.data) {
          if (params.data.length > 0) {
            this.setState({ data: params.data });
          } else {
            this.showAlert("Error", "No data Found");
          }
        } else {
          this.setState({
            data: responseJson.data,
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductDetailsApiCallId: string = "";
  DeleteProductDetailsApiCallId:string ="";
  useDetailsApiCallID:string ="";
  getProductDetails = async (productId: number) => {
    const token = await localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  DeleteProductDetails = async (productId: number) => {
    const token = await localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteProductDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  


  handleGetProductDetailsResponse = (responseJson: any) => {
 
      this.setState({
        selectedProduct: responseJson.data,
      });
  
  };


  getLoggendInUserData() {

    const headers = {

      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken')
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.useDetailsApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.UserInfoApi}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.apiMethodTypeGet

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }
  saveLoggedInUserData(responseJson: any) {
 
      localStorage.setItem('user', JSON.stringify(responseJson))
      
    
  }

  handleNavigation = (screenName: string, param: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), String(param));
    runEngine.sendMessage(message.id, message);
}

  hadelOpenList =()=>{
    this.setState({openDrw: !this.state.openDrw})
  }

  // Customizable Area End
}
