Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "bx_block_inventory_management/products";
exports.UserInfoApi = 'account_block/accounts/logged_user';
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.excludeOutOfStockTxt = "Exclude out of stock";
exports.priceRangeTxt = "Price range";
exports.brandTxt = "Brand";
exports.categoryTxt = "Category";
exports.cancel = "Cancel";
exports.apply = "Apply";
exports.searchForBrandTxt = "Search for a Brand";
exports.searchForCategoryTxt = "Search for a Category";
exports.webFilterOptionsTxt = "Web Filter Options";
exports.emptyBlockTxt = "Empty Block";

exports.filterTxt = "Filter";
// Customizable Area End
